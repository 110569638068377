@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../../../../mixins/breakpoints';

.PopularDestination {
  display: flex;

  @include breakpoints.hotels-breakpoint-mobile {
    margin: 0 tokens.bpk-spacing-md() tokens.bpk-spacing-md() 0;
    padding: tokens.bpk-spacing-base() tokens.bpk-spacing-base();
    border-radius: tokens.$bpk-border-radius-xs * 2;
    outline: none;
    background-color: tokens.$bpk-canvas-contrast-day;
    color: tokens.$bpk-core-primary-day;

    @include utils.bpk-rtl {
      margin: 0 0 tokens.bpk-spacing-md() tokens.bpk-spacing-md();
    }

    &:hover {
      background-color: tokens.$bpk-surface-highlight-day;
    }
  }

  @include breakpoints.hotels-breakpoint-above-mobile {
    width: 45%;
    padding-bottom: tokens.bpk-spacing-base();
    flex-direction: row;
    flex: 1 0 auto;
  }

  &:hover {
    background-color: tokens.$bpk-canvas-contrast-day;
  }

  &__iconAndValue {
    display: flex;
  }

  &__icon {
    display: table-cell;
    margin-right: tokens.bpk-spacing-base();
    vertical-align: top;
    fill: tokens.$bpk-text-secondary-day;

    @include utils.bpk-rtl {
      margin-right: 0;
      margin-left: tokens.bpk-spacing-base();
    }

    @include breakpoints.hotels-breakpoint-mobile {
      margin-right: tokens.bpk-spacing-md();

      @include utils.bpk-rtl {
        margin-right: 0;
        margin-left: tokens.bpk-spacing-md();
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    vertical-align: top;

    @include breakpoints.hotels-breakpoint-above-mobile {
      max-width: 70%;
    }
  }

  &__value {
    width: 100%;
    color: tokens.$bpk-core-primary-day;

    @include breakpoints.hotels-breakpoint-above-mobile {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__subHeading {
    width: 100%;
    color: tokens.$bpk-core-primary-day;

    @include breakpoints.hotels-breakpoint-above-mobile {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__tertiaryLabel {
    align-self: flex-end;

    @include typography.bpk-text;
    @include typography.bpk-text--xs;
  }
}
