@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../../../mixins/breakpoints.scss' as mixins-breakpoints;

.RecentSearches {
  &__recentSearchHeader {
    display: flex;
    padding: tokens.bpk-spacing-base() tokens.bpk-spacing-lg()
      tokens.bpk-spacing-base() tokens.bpk-spacing-base();
    justify-content: space-between;
    align-items: center;
    background-color: tokens.$bpk-surface-default-day;

    @include mixins-breakpoints.hotels-breakpoint-mobile {
      padding: tokens.bpk-spacing-base() tokens.bpk-spacing-lg() 0
        tokens.bpk-spacing-base();
    }
  }

  &__recentTitle {
    font-weight: tokens.$bpk-font-weight-bold;

    @include breakpoints.bpk-breakpoint-mobile {
      font-weight: tokens.$bpk-font-weight-black;
    }
  }

  &__btn {
    outline: none;
    color: tokens.$bpk-core-accent-day;

    button {
      padding-left: 0;
      outline: none;
      font-size: tokens.$bpk-font-size-base;
      box-shadow: none;

      @include mixins-breakpoints.hotels-breakpoint-mobile {
        padding-right: 0;
      }

      &:hover {
        outline: none;
      }

      &:active {
        outline: none;
      }
    }
  }

  &__recentSearchItems {
    display: flex;
    padding: 0 tokens.bpk-spacing-lg();
    flex-direction: column;
    background-color: tokens.$bpk-surface-default-day;
  }
}
