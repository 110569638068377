@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

$inputs-spacing-top: tokens.bpk-spacing-base();

@mixin form-field-spacing {
  margin-left: tokens.bpk-spacing-base();

  @include utils.bpk-rtl {
    margin-right: tokens.bpk-spacing-base();
    margin-left: unset;
  }
}
