@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.RecentSearchAndPopularsMobile {
  &__popularTitle {
    padding: tokens.bpk-spacing-lg();
    background-color: tokens.$bpk-surface-default-day;
    font-weight: tokens.$bpk-font-weight-black;
  }

  &__popularDestinations {
    display: flex;
    padding: 0 tokens.bpk-spacing-lg();
    flex-wrap: wrap;
    background-color: tokens.$bpk-surface-default-day;
  }
}
