@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../../../../mixins/breakpoints';

.RecentSearchItem {
  &__hiddenAboveMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__recentSearch {
    display: flex;
    width: 80%;
    padding-top: 0.75 * tokens.bpk-spacing-base();
    padding-bottom: 0.75 * tokens.bpk-spacing-base();
    flex-shrink: 2;
    color: tokens.$bpk-core-primary-day;
    white-space: nowrap;

    @include breakpoints.hotels-breakpoint-mobile {
      width: 100%;
      padding: tokens.bpk-spacing-base() 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background-color: tokens.$bpk-canvas-contrast-day;
    }

    &--icon {
      display: flex;
      margin-right: tokens.bpk-spacing-base();
      flex-shrink: 0;
      vertical-align: top;
      fill: tokens.$bpk-text-secondary-day;

      @include utils.bpk-rtl {
        margin-right: 0;
        margin-left: tokens.bpk-spacing-base();
      }

      @include breakpoints.hotels-breakpoint-mobile {
        fill: tokens.$bpk-line-day;
      }
    }

    &--entityItem {
      @include breakpoints.hotels-breakpoint-above-mobile {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &--item {
      &::before {
        content: '・';
      }
    }

    &--adultItem {
      display: inline-flex;

      &::before {
        content: '・';
      }
    }
  }

  &__arrowIcon {
    @include utils.bpk-rtl {
      margin-right: unset;
      margin-left: -(tokens.bpk-spacing-md()) * 0.5;
    }

    :first-child {
      margin-right: -(tokens.bpk-spacing-md()) * 0.5;
      padding: tokens.bpk-spacing-md() * 0.5;
      flex-shrink: 0;
    }
  }

  &__rectangleShape {
    position: absolute;
    right: tokens.$bpk-font-size-xxxl;
    width: tokens.$bpk-input-large-height;
    height: 38 * tokens.$bpk-one-pixel-rem;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background: linear-gradient(
      90deg,
      tokens.$bpk-surface-default-day 0%,
      rgba(tokens.$bpk-surface-default-day, 0) 100%
    );

    @include utils.bpk-rtl {
      right: unset;
      left: tokens.$bpk-font-size-xxxl;
      background: linear-gradient(
        -90deg,
        tokens.$bpk-surface-default-day 0%,
        rgba(tokens.$bpk-surface-default-day, 0) 100%
      );
    }
  }
}
