@use '@skyscanner/backpack-web/unstable__bpk-mixins/borders';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.GuestsRoomsChildren {
  width: 100%;

  &__sectionListTitle {
    position: absolute;
    left: tokens.bpk-spacing-md() * 7;

    @include utils.bpk-rtl {
      right: tokens.bpk-spacing-md() * 7;
      left: unset;
    }
  }

  &__sectionListTitleOnlyText {
    left: tokens.bpk-spacing-base() * 3;
    font-weight: tokens.$bpk-font-weight-bold;

    @include utils.bpk-rtl {
      right: tokens.bpk-spacing-base() * 3;
      left: unset;
    }
  }

  &__sectionListValue {
    position: absolute;
    right: tokens.bpk-spacing-base() * 5;
  }

  &__childAgeTitle {
    padding: tokens.bpk-spacing-base() 0 0 tokens.bpk-spacing-base();

    @include borders.bpk-border-top-sm(tokens.$bpk-surface-highlight-day);

    @include utils.bpk-rtl {
      padding: tokens.bpk-spacing-base() tokens.bpk-spacing-base() 0 0;
    }
  }

  &__sectionListChildren {
    box-shadow: none;
  }

  &__smallAdult {
    margin-top: tokens.bpk-spacing-md() * 0.5;
    margin-left: tokens.bpk-spacing-md() * 0.5;
  }

  &__sectionListRoom {
    box-shadow: none;

    @include borders.bpk-border-top-sm(tokens.$bpk-surface-highlight-day);
  }

  &__popover {
    display: inline-list-item;
    width: 100%;
    padding: tokens.bpk-spacing-md() tokens.bpk-spacing-base()
      tokens.bpk-spacing-base();
    box-sizing: border-box;

    &--selectAge {
      width: 47% !important; // stylelint-disable-line declaration-no-important
      margin-top: tokens.bpk-spacing-base();
    }

    &--selectAge:nth-child(2n) {
      margin-left: 5%;
    }
  }
}
