@use 'sass:math';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/borders';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/forms';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.DestinationSelector {
  &__input {
    margin-top: tokens.bpk-spacing-md();

    @include breakpoints.bpk-breakpoint-above-tablet {
      height: tokens.bpk-spacing-lg() * 2;
      border-radius: math.div(tokens.bpk-spacing-base(), 3) 0 0
        math.div(tokens.bpk-spacing-base(), 3);

      input {
        border: tokens.$bpk-input-border;

        @include forms.bpk-input--large;
        @include forms.bpk-input--docked-first-child;
      }

      button {
        @include forms.bpk-input--large;
      }
    }

    @include breakpoints.bpk-breakpoint-mobile {
      border: tokens.$bpk-border-size-sm solid
        tokens.$bpk-color-sky-blue-tint-02;
      border-radius: tokens.$bpk-border-radius-sm;
    }
  }

  &__popoverInput {
    margin-top: tokens.bpk-spacing-md();
    border-radius: tokens.$bpk-input-border-radius 0 0
      tokens.$bpk-input-border-radius;
  }

  &__section {
    display: flex;
    width: 100%;
    flex-direction: column;

    &:hover {
      background-color: tokens.$bpk-surface-default-day;
    }
  }

  &__suggestion {
    display: flex;
    width: 100%;
    padding: tokens.bpk-spacing-base() tokens.bpk-spacing-base();
    flex-direction: row;
    color: tokens.$bpk-core-primary-day;

    &:hover {
      background-color: tokens.$bpk-canvas-contrast-day;
    }

    &--pois {
      display: flex;
      padding: 0 0 tokens.bpk-spacing-base() tokens.bpk-spacing-xxl();
      flex-wrap: wrap;
      background-color: tokens.$bpk-surface-default-day;
    }

    &--poi {
      margin: tokens.bpk-spacing-md();
      padding: tokens.bpk-spacing-base() tokens.bpk-spacing-base();
      border-radius: tokens.$bpk-border-radius-xs * 2;
      outline: none;
      background-color: tokens.$bpk-canvas-contrast-day;
      color: tokens.$bpk-core-primary-day;

      &:hover {
        background-color: tokens.$bpk-surface-highlight-day;
      }
    }
  }

  &__label {
    position: absolute;
    top: math.div(tokens.bpk-spacing-lg(), 3);
    left: tokens.bpk-spacing-base();
    z-index: tokens.$bpk-zindex-tooltip;
    color: tokens.$bpk-text-secondary-day;
    font-weight: math.div(tokens.$bpk-font-weight-bold, 7) * 2;
  }

  &__suggestionsContainerOpen {
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    z-index: tokens.$bpk-zindex-autosuggest;
    outline: none;
  }

  &__suggestionsContainerOpen::before,
  &__suggestionsContainerOpen::after {
    display: none;
  }

  &__suggestionsList {
    margin-bottom: 0;
    padding: 0;
    border: unset;
    border-radius: unset;
    background: tokens.$bpk-surface-default-day;
    list-style: none;
    box-shadow: unset;
    margin-block-start: tokens.bpk-spacing-md();
  }

  &__suggestionItem {
    cursor: pointer;

    @include typography.bpk-text;
    @include typography.bpk-text--base;

    &:not(:last-child) {
      @include borders.bpk-border-bottom-sm(tokens.$bpk-surface-highlight-day);
    }

    &:active {
      background-color: tokens.$bpk-canvas-contrast-day;
    }

    &--highlighted {
      background-color: tokens.$bpk-canvas-contrast-day;
    }
  }
}
