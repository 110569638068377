@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.DateRange {
  position: relative;
  display: flex;
  margin: 0 tokens.bpk-spacing-sm();

  button {
    width: 100%;
    min-width: tokens.bpk-spacing-lg() * 3.8;
    height: 100%;
    padding: 0 tokens.bpk-spacing-base() 0 tokens.bpk-spacing-xl();
    border: none;
    border-radius: tokens.$bpk-border-radius-xs;
    background-color: tokens.$bpk-surface-default-day;
  }

  p {
    line-height: tokens.bpk-spacing-base();
    text-align: right;
    white-space: nowrap;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: tokens.bpk-spacing-md();
    transform: translate3d(0, -50%, 0);
    fill: tokens.$bpk-input-disabled-color;
  }
}
