@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';

.ApplyFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: tokens.$bpk-line-height-xs tokens.$bpk-line-height-xs
    tokens.$bpk-line-height-xs tokens.bpk-spacing-base();
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: tokens.$bpk-border-size-sm solid
    tokens.$bpk-color-sky-gray-tint-06;
  background: tokens.$bpk-surface-default-day;
  color: tokens.$bpk-text-secondary-day;

  &__applyButton {
    width: 100%;
    padding: tokens.$bpk-one-pixel-rem * 11 tokens.$bpk-one-pixel-rem * 58.5;
    flex: 1;

    @media (max-width: tokens.$bpk-one-pixel-rem * 320) {
      padding: tokens.bpk-spacing-base() tokens.bpk-spacing-xxl()
        tokens.bpk-spacing-base() tokens.bpk-spacing-xxl();
    }
  }

  &__applyNight {
    span {
      font-weight: bold;

      @include typography.bpk-text--black;
    }
  }

  &__selectLabel {
    width: 35%;
    overflow-wrap: break-word;

    @media (max-width: tokens.$bpk-one-pixel-rem * 320) {
      width: 45%;
    }
  }
}
