@use 'sass:math';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.ScrollableDateRangeSelector {
  display: flex;
  height: 100%;
  padding: 0 0 tokens.bpk-spacing-base() 0;
  flex-direction: column;
  flex: 1;
  background: tokens.$bpk-surface-default-day;
  overflow-y: scroll;

  &__header {
    display: flex;
    width: 100%;
    height: auto;
    margin: tokens.$bpk-one-pixel-rem * 4 0 0 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-inline-start: tokens.$bpk-line-height-xs;
  }

  &__alert {
    width: 100%;
    padding-top: tokens.$bpk-line-height-xs * 0.5;
    padding-inline-end: tokens.$bpk-line-height-xs;

    section {
      box-shadow: 0 0 0 tokens.$bpk-border-size-sm
        tokens.$bpk-label-disabled-color;
    }

    div[class*='children-container'] {
      padding-inline-end: tokens.bpk-spacing-xxl();
      padding-inline-start: tokens.bpk-spacing-xl();
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: tokens.$bpk-text-secondary-day;

    svg {
      margin-inline-start: math.div(tokens.bpk-spacing-base(), 3);
    }
  }

  &__content {
    display: flex;
    margin-top: tokens.bpk-spacing-lg();
    padding-bottom: tokens.bpk-spacing-lg() * 2;
    flex-direction: column;
    flex: 1;
  }

  &__dateRange {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date {
    display: flex;
    width: calc((100% - #{tokens.bpk-spacing-base()}) / 2);
    margin-bottom: tokens.bpk-spacing-base();
    flex-direction: column;

    &--checkIn {
      margin-inline-end: tokens.bpk-spacing-base() * 0.5;
      margin-inline-start: tokens.bpk-spacing-base();
    }

    &--checkOut {
      margin-inline-end: tokens.$bpk-one-pixel-rem * 15;
      margin-inline-start: tokens.bpk-spacing-base() * 0.5;
    }

    &--input {
      height: tokens.$bpk-line-height-xs * 3;
      margin-top: tokens.bpk-spacing-md();
      padding: tokens.bpk-spacing-base();
    }
  }

  &__info {
    &--title {
      text-align: center;
    }

    &--content {
      margin-top: tokens.$bpk-line-height-xs;
      text-align: center;
    }
  }
}
