@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.StackingContextReset {
  position: relative;
  z-index: tokens.$bpk-zindex-modal;

  @include breakpoints.bpk-breakpoint-mobile {
    display: flex;
    height: 100%;
  }

  @include breakpoints.bpk-breakpoint-above-mobile {
    background-color: tokens.$bpk-surface-default-day;
  }
}
