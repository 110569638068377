// These px values are taken from header oc
/* stylelint-disable unit-disallowed-list */
$header-breakpoint-max: 1090px;
$header-breakpoint-min: 500px;
$header-width-dayview-desktop: 1180px;
$header-width-dayview-breakpoint: 1245px;
$header-width-max: 1048px;
$header-width-middle: 95%;
$header-breakpoint-mobile-layout: 600px;
$header-width-min: 100%;
$header-padding-min: 0.75rem;
$mobile-breakpoint: 700px;
$smaller-desktop-breakpoint: 950px;
$large-wide-desktop-breakpoint: 1600px;
$medium-wide-desktop-breakpoint: 1400px;
$small-wide-desktop-breakpoint: 1200px;
$mobile-header-height: 3.5rem;
$iphone-se-width: 360px;
$iphone-x-width: 375px;
$tablet-breakpoint: 1025px;
/* stylelint-enable unit-disallowed-list */

// Above 1090px header is limited to 1048px wide
@mixin header-breakpoint-max() {
  @media (min-width: $header-breakpoint-max) {
    @content;
  }
}

// Below 1090px and above 500px header is 95% of the width of the page
@mixin header-breakpoint-middle() {
  @media (min-width: $header-breakpoint-min) and (max-width: $header-breakpoint-max - 1) {
    @content;
  }
}

// Below 500px header is 100% of the width of the page with
@mixin header-breakpoint-min() {
  @media (max-width: $header-breakpoint-min) {
    @content;
  }
}

// Below 600px the header switches from mobile to tablet layout
@mixin header-breakpoint-mobile-layout() {
  @media (max-width: $header-breakpoint-mobile-layout - 1) {
    @content;
  }
}

@mixin header-width-container() {
  @include header-breakpoint-max {
    width: $header-width-max;
  }

  @include header-breakpoint-middle {
    width: $header-width-middle;
  }

  @include header-breakpoint-min {
    width: calc(
      #{$header-width-min} - #{$header-padding-min} - #{$header-padding-min}
    );
    margin-right: $header-padding-min;
    margin-left: $header-padding-min;
  }
}

// Hotels specific "mobile" breakpoint where layouts
@mixin hotels-breakpoint-mobile() {
  @media (max-width: $mobile-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-above-mobile() {
  @media (min-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin hotels-breakpoint-smaller-desktop() {
  @media (min-width: $mobile-breakpoint) and (max-width: $smaller-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-below-smaller-desktop() {
  @media (max-width: $smaller-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-above-smaller-desktop() {
  @media (min-width: $smaller-desktop-breakpoint) {
    @content;
  }
}

@mixin hotels-breakpoint-large-wide-desktop() {
  @media (min-width: $large-wide-desktop-breakpoint) {
    @content;
  }
}

@mixin hotels-breakpoint-medium-wide-desktop() {
  @media (min-width: $medium-wide-desktop-breakpoint) and (max-width: $large-wide-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-small-wide-desktop() {
  @media (min-width: $small-wide-desktop-breakpoint) and (max-width: $medium-wide-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-xsmall-wide-desktop() {
  @media (min-width: $smaller-desktop-breakpoint) and (max-width: $small-wide-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-above-small-wide-desktop() {
  @media (min-width: $small-wide-desktop-breakpoint) {
    @content;
  }
}

@mixin hotels-breakpoint-small-and-medium-wide-desktop() {
  @media (min-width: $small-wide-desktop-breakpoint) and (max-width: $large-wide-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin hotels-breakpoint-below-small-wide-desktop() {
  @media (max-width: $small-wide-desktop-breakpoint - 1) {
    @content;
  }
}

@mixin breakpoint-iphone-se() {
  @media (max-width: $iphone-se-width) {
    @content;
  }
}

@mixin breakpoint-iphone-x() {
  @media (max-width: $iphone-x-width) {
    @content;
  }
}

@mixin breakpoint-above-iphone-se() {
  @media (min-width: $iphone-se-width + 1) {
    @content;
  }
}

@mixin hotels-breakpoint-tablet-and-small-wide-desktop() {
  @media (min-width: $tablet-breakpoint) and (max-width: $small-wide-desktop-breakpoint - 1) {
    @content;
  }
}
