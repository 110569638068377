@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../../../../mixins/breakpoints.scss' as mixins-breakpoints;

span[class*='BpkText_typography.bpk-text__'].Preference__title {
  margin-right: tokens.bpk-spacing-lg();
}

.Preference {
  display: flex;
  flex-wrap: wrap;
  color: tokens.$bpk-surface-default-day;

  @include breakpoints.bpk-breakpoint-mobile {
    margin-bottom: tokens.bpk-spacing-base();
  }

  &__item {
    margin: tokens.bpk-spacing-base() * 0.5 tokens.bpk-spacing-lg()
      tokens.bpk-spacing-base() * 0.5 0;
    font-size: tokens.$bpk-font-size-sm;
    white-space: nowrap;

    @include utils.bpk-rtl {
      margin: tokens.bpk-spacing-base() * 0.5 0 tokens.bpk-spacing-base() * 0.5
        tokens.bpk-spacing-lg();
    }

    @include breakpoints.bpk-breakpoint-mobile {
      margin: tokens.bpk-spacing-base() tokens.bpk-spacing-lg() 0 0;

      @include utils.bpk-rtl {
        margin: tokens.bpk-spacing-base() 0 0 tokens.bpk-spacing-lg();
      }
    }

    @include mixins-breakpoints.breakpoint-iphone-x {
      margin: tokens.bpk-spacing-base() tokens.bpk-spacing-base() 0 0;

      @include utils.bpk-rtl {
        margin: tokens.bpk-spacing-base() 0 0 tokens.bpk-spacing-base();
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;

    @include utils.bpk-rtl {
      margin-left: unset;
      margin-left: tokens.bpk-spacing-xl();
    }

    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }
  }
}
