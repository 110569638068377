@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/forms';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.ChappedLayout {
  display: flex;
  width: 100%;
  padding: tokens.bpk-spacing-base();
  flex-direction: row;
  border-radius: tokens.$bpk-border-radius-sm;
  background-color: rgba(tokens.$bpk-canvas-contrast-day, 0.5);

  &__inputs {
    display: flex;
    width: 35%;
    margin-top: -(tokens.bpk-spacing-md());

    @include utils.bpk-rtl {
      margin-left: tokens.bpk-spacing-base();
    }

    &:focus-within {
      button {
        background-color: tokens.$bpk-status-success-spot-day;
        color: tokens.$bpk-surface-default-day;

        // stylelint-disable-next-line selector-max-compound-selectors
        svg {
          fill: tokens.$bpk-surface-default-day;
        }
      }
    }

    input {
      /* stylelint-disable declaration-no-important */
      height: tokens.bpk-spacing-lg() * 2 !important;
      border-width: tokens.$bpk-border-size-sm 0 tokens.$bpk-border-size-sm
        tokens.$bpk-border-size-sm !important;
      border-style: solid !important;
      border-radius: tokens.$bpk-border-radius-xs 0 0
        tokens.$bpk-border-radius-xs !important;
      border-color: tokens.$bpk-canvas-contrast-day !important;
      outline: none;

      @include utils.bpk-rtl {
        border-width: tokens.$bpk-border-size-sm tokens.$bpk-border-size-sm
          tokens.bpk-spacing-md() tokens.$bpk-border-size-sm 0 !important;
        border-radius: 0 tokens.$bpk-border-radius-xs
          tokens.$bpk-border-radius-xs 0 !important;
      }
      /* stylelint-enable declaration-no-important */
    }
  }

  &__location {
    margin-right: 0;
  }

  &__searchButton {
    display: flex;
    margin-top: tokens.bpk-spacing-md();
    padding: 9 * tokens.$bpk-one-pixel-rem tokens.$bpk-one-pixel-rem * 12;
    align-items: center;
    border-left: none !important; // stylelint-disable-line declaration-no-important
    border-width: tokens.$bpk-border-size-sm;
    border-style: solid;
    border-radius: 0 tokens.$bpk-border-radius-xs tokens.$bpk-border-radius-xs 0;
    border-color: tokens.$bpk-canvas-contrast-day;
    outline: none;
    background-color: tokens.$bpk-surface-default-day;
    color: tokens.$bpk-text-secondary-day;
    cursor: pointer;

    @include utils.bpk-rtl {
      border-right: none;
      border-left: tokens.$bpk-border-size-sm solid
        tokens.$bpk-canvas-contrast-day !important; // stylelint-disable-line declaration-no-important

      border-radius: tokens.$bpk-border-radius-xs 0 0
        tokens.$bpk-border-radius-xs;
    }

    &:hover {
      background-color: tokens.$bpk-status-success-spot-day;
      color: tokens.$bpk-surface-default-day;

      svg {
        fill: tokens.$bpk-surface-default-day;
      }
    }
  }

  &__inputsDatesGuest {
    margin-left: tokens.bpk-spacing-base();

    @include breakpoints.bpk-breakpoint-tablet {
      margin-left: tokens.bpk-spacing-base();
    }
  }

  &__guestsRoomsChildren {
    width: 30%;
    margin-top: -(tokens.bpk-spacing-md());
    margin-left: tokens.bpk-spacing-base();

    @include breakpoints.bpk-breakpoint-tablet {
      margin-left: 0;
    }

    input {
      border-radius: tokens.$bpk-select-border-radius !important; // stylelint-disable-line declaration-no-important

      @include forms.bpk-select--large;
    }
  }
}
