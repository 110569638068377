@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/forms';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.GuestsRoomsChildrenPopover {
  @include breakpoints.bpk-breakpoint-above-mobile {
    width: 100%;
  }

  &__popoverInput {
    margin-top: tokens.bpk-spacing-md();

    @include breakpoints.bpk-breakpoint-above-tablet {
      @include forms.bpk-select--large;
      @include forms.bpk-select--docked-last-child;
    }
  }

  &__popoverContent {
    width: tokens.bpk-spacing-lg() * 10;

    @include breakpoints.bpk-breakpoint-above-tablet {
      width: tokens.bpk-spacing-xxl() * 10;
    }
  }
}
