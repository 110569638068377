@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../mixins/breakpoints';

.SearchBar {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: tokens.$bpk-border-radius-sm;
  background-color: tokens.$bpk-surface-contrast-day;

  @include breakpoints.hotels-breakpoint-mobile {
    border-radius: 0;
  }

  &__searchControls {
    top: 0;
    z-index: 9;
    width: 100%;
    padding: tokens.bpk-spacing-base();
    transition: 0.5s ease-in;
    border-radius: tokens.$bpk-border-radius-sm;
    background-color: tokens.$bpk-surface-contrast-day;

    &Expand {
      padding: tokens.bpk-spacing-base();
      box-shadow: none;

      @include breakpoints.hotels-breakpoint-above-small-wide-desktop {
        padding: 0;
      }
    }
  }
}
