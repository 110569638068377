@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.RecentSearchAndPopulars {
  border-radius: tokens.$bpk-border-radius-sm;
  box-shadow: tokens.$bpk-box-shadow-lg;

  &__popularTitle {
    padding: tokens.bpk-spacing-base() 0 tokens.bpk-spacing-base()
      tokens.bpk-spacing-base();
    background-color: tokens.$bpk-surface-default-day;
    color: tokens.$bpk-core-primary-day;
    font-weight: 700;
  }

  &__popularDestinations {
    display: flex;
    padding: 0 tokens.bpk-spacing-lg() tokens.bpk-spacing-lg()
      tokens.bpk-spacing-lg();
    flex-wrap: wrap;
    background-color: tokens.$bpk-surface-default-day;
  }
}
