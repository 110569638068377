@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../../../mixins/breakpoints.scss' as mixins-breakpoints;

.PlaceHolder {
  position: absolute;
  top: tokens.$bpk-line-height-xxxl;
  height: auto;
  margin-top: 0;
  margin-bottom: tokens.bpk-spacing-md();
  border: 0;
  outline: none;
  background-color: transparent;
  color: tokens.$bpk-text-secondary-dark-color;
  cursor: text;
  padding-block: 0;
  padding-inline: tokens.bpk-spacing-md() 0;
  pointer-events: none;

  @include breakpoints.bpk-breakpoint-above-tablet {
    font-size: tokens.$bpk-font-size-base;
    font-weight: tokens.$bpk-font-weight-bold;
    line-height: tokens.$bpk-line-height-base; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    padding-inline: tokens.bpk-spacing-base() 0;
  }

  @include mixins-breakpoints.hotels-breakpoint-tablet-and-small-wide-desktop {
    top: tokens.$bpk-line-height-xxxxl;
  }

  @include mixins-breakpoints.hotels-breakpoint-above-small-wide-desktop {
    top: tokens.$bpk-line-height-xxxl;
  }

  &__destination {
    visibility: hidden;
  }
}
