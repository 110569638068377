@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../mixins/breakpoints.scss' as mixins-breakpoints;

.SearchControls {
  @include breakpoints.bpk-breakpoint-above-tablet {
    display: flex;
    margin: tokens.bpk-spacing-base() 0;
    justify-content: space-between;
    align-items: center;
  }

  @include mixins-breakpoints.hotels-breakpoint-above-small-wide-desktop {
    margin: tokens.bpk-spacing-lg() 0;
  }

  &__destinationErr {
    &--default {
      margin: tokens.bpk-spacing-md() 0 tokens.bpk-spacing-lg() 0;

      @include breakpoints.bpk-breakpoint-mobile {
        margin-bottom: tokens.bpk-spacing-base();
      }
    }

    &--inline {
      margin: tokens.bpk-spacing-lg() 0 0 0;
    }

    &--message {
      // stylelint-disable-next-line prettier/prettier
      padding: tokens.$bpk-border-radius-xs * 0.5 tokens.$bpk-border-radius-xs * 2;
      border-radius: tokens.$bpk-border-radius-xs;
      background-color: tokens.$bpk-status-danger-spot-day;
      color: tokens.$bpk-surface-default-day;
    }
  }
}
