@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../mixins';

.FormField {
  flex-grow: 1;

  &__withoutLabel {
    margin-inline: 0;
  }

  &__hideLabel {
    @include utils.bpk-visually-hidden;
  }

  &__label--light {
    color: tokens.$bpk-surface-default-day;
    white-space: nowrap;
  }

  &__label--newHeaderDateRange {
    display: flex;
    align-items: center;

    svg {
      margin: 0 tokens.bpk-spacing-md() 0 tokens.bpk-spacing-base();
      fill: tokens.$bpk-core-primary-day;
    }
  }
}
