@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../mixins';

.ExpandableLayout {
  &__inputs {
    display: flex;
    flex-direction: column;

    @include breakpoints.bpk-breakpoint-above-tablet {
      margin: 0;
      flex-direction: row;
    }

    &DatesGuest {
      display: flex;
      margin-top: mixins.$inputs-spacing-top;
      flex-direction: column;

      @include breakpoints.bpk-breakpoint-above-mobile {
        flex-direction: row;
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        width: 50%;
        margin-top: 0;
      }

      &Inline {
        @include breakpoints.bpk-breakpoint-above-tablet {
          label {
            margin-top: tokens.bpk-spacing-md();
            color: tokens.$bpk-text-secondary-day;
            font-size: tokens.$bpk-font-size-xs;
            font-weight: tokens.$bpk-font-weight-book;
            margin-inline: tokens.bpk-spacing-base() 0;
          }

          input {
            height: auto;
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: tokens.bpk-spacing-md();
            border: 0;
            outline: none;
            color: tokens.$bpk-core-accent-day;
            font-size: tokens.$bpk-font-size-base;
            font-weight: tokens.$bpk-font-weight-bold;
            line-height: tokens.$bpk-line-height-base; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
            padding-inline-end: 0;
          }

          // stylelint-disable-next-line selector-max-compound-selectors
          div[class*='FormField_FormField'] {
            border-left: tokens.$bpk-border-size-sm solid
              tokens.$bpk-surface-highlight-day;
          }
        }
      }
    }

    &Inline {
      flex: 1;

      @include breakpoints.bpk-breakpoint-above-tablet {
        background-color: tokens.$bpk-surface-default-day;
      }
    }
  }

  &__input {
    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: tokens.bpk-spacing-md();
    }
  }

  &__location {
    @include breakpoints.bpk-breakpoint-above-tablet {
      width: 50%;
    }

    &Inline {
      @include breakpoints.bpk-breakpoint-above-tablet {
        // stylelint-disable-next-line prettier/prettier
        border-radius: tokens.bpk-spacing-md() * 0.5 0 0 tokens.bpk-spacing-md() * 0.5;

        label {
          margin-top: tokens.bpk-spacing-md();
          color: tokens.$bpk-text-secondary-day;
          font-size: tokens.$bpk-font-size-xs;
          font-weight: tokens.$bpk-font-weight-book;
          margin-inline: tokens.bpk-spacing-base() 0;
        }

        input {
          height: auto;
          margin-top: 0;
          margin-bottom: tokens.bpk-spacing-md();
          padding-top: 0;
          padding-bottom: 0;
          border: 0;
          outline: none;
          color: tokens.$bpk-core-accent-day;
          font-size: tokens.$bpk-font-size-base;
          font-weight: tokens.$bpk-font-weight-bold;
          line-height: tokens.$bpk-line-height-base; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
          padding-inline: tokens.bpk-spacing-base() 0;
        }
      }

      @include utils.bpk-rtl {
        border-left: tokens.$bpk-border-size-sm solid
          tokens.$bpk-surface-highlight-day;
      }
    }
  }

  &__dateRange {
    @include breakpoints.bpk-breakpoint-above-mobile {
      width: 50%;
    }
  }

  &__guestsRoomsChildren {
    @include breakpoints.bpk-breakpoint-above-mobile {
      width: 50%;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: mixins.$inputs-spacing-top;
    }

    &--withoutLabel {
      margin-top: 0;
      flex: 1;
    }
  }

  &__formField {
    &:not(:first-of-type) {
      @include breakpoints.bpk-breakpoint-tablet {
        @include mixins.form-field-spacing;
      }
    }
  }

  &__ctaWrapper {
    margin-top: tokens.bpk-spacing-base();
    text-align: right;

    &Inline {
      margin-top: tokens.bpk-spacing-lg();
      margin-left: tokens.bpk-spacing-base();

      @include utils.bpk-rtl {
        margin-right: tokens.bpk-spacing-base();
        margin-left: unset;
      }

      @include breakpoints.bpk-breakpoint-above-tablet {
        height: tokens.bpk-spacing-md() * 7;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;

        button {
          height: 100%;
          // stylelint-disable-next-line prettier/prettier
          border-radius: 0 tokens.bpk-spacing-md() * 0.5 tokens.bpk-spacing-md() * 0.5 0;
          outline: none;
        }
      }
    }

    .ExpandableLayout__cta {
      background-color: tokens.$bpk-core-accent-day;

      @include breakpoints.bpk-breakpoint-mobile {
        width: 100%;
      }
    }
  }

  &__preferenceOnSearchBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: tokens.$bpk-surface-default-day;

    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: tokens.bpk-spacing-md();
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__preferenceContainer {
    flex: 1;
  }
}
