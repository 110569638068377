@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../../mixins/breakpoints';

.BackHeader {
  z-index: 1001;
  width: 100%;
  background-color: tokens.$bpk-surface-default-day;

  span {
    color: tokens.$bpk-core-primary-day;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__button[class*='BpkCloseButton_bpk-close-button__'] {
    svg {
      fill: tokens.$bpk-text-secondary-day;
    }
  }
}
