@use 'sass:math';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.FullScreenWrapper {
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: column;
  flex: 1;
  background-color: tokens.$bpk-surface-default-day;

  &__header {
    display: flex;
    align-items: center;
  }

  &__content {
    padding: math.div(tokens.bpk-spacing-lg(), 1.5);
    flex: 1 1;
    overflow-y: auto;

    &--withFooter {
      padding-bottom: tokens.bpk-spacing-lg() * 4;
    }
  }

  &__title {
    font-weight: tokens.$bpk-font-weight-bold * 0.71;

    &Space {
      height: tokens.bpk-spacing-lg();
    }
  }

  &__backHeader {
    position: relative;
    height: 100%;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
  }
}
