@use '@skyscanner/backpack-web/unstable__bpk-mixins/borders';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.GuestsRoomsChildrenPortal {
  height: 100%;

  &__input {
    position: relative;
    height: 100%;
    color: tokens.$bpk-input-disabled-color;
    text-align: right;

    button {
      width: 100%;
      min-width: tokens.bpk-spacing-lg() * 1.75;
      height: 100%;
      border: none;
      border-radius: tokens.$bpk-border-radius-xs;
      background-color: tokens.$bpk-surface-default-day;
      font-size: tokens.$bpk-font-size-xs;
      padding-inline: tokens.bpk-spacing-lg() tokens.bpk-spacing-base();
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    fill: tokens.$bpk-input-disabled-color;
  }

  &__applyButton {
    width: 100%;
  }

  &__footer {
    padding: tokens.bpk-spacing-base();
  }

  &__header {
    @include borders.bpk-border-bottom-sm(tokens.$bpk-surface-highlight-day);
  }
}
