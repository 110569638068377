@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../mixins';
@use '../../../mixins/breakpoints';

.HorizontalLayout {
  display: flex;
  margin: 0;
  flex-direction: row;

  &__datesGuest {
    display: flex;
    flex-direction: row;
  }

  &__input {
    border: tokens.$bpk-input-border;
  }

  &__guestsRoomsChildren {
    margin-top: 0;
    flex: 1;
  }

  &__button {
    width: 100%;
    margin-top: tokens.bpk-spacing-base();
    background-color: tokens.$bpk-core-accent-day;
  }
}
