@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/forms';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use '../mixins';

.DateRangeSelector {
  display: flex;

  &__showNights {
    position: relative;
  }

  &__input {
    margin-top: tokens.bpk-spacing-md();

    @include breakpoints.bpk-breakpoint-above-tablet {
      @include forms.bpk-input--large;
      @include forms.bpk-input--docked-middle-child;
    }
  }

  &__checkInForm {
    margin-right: 0;

    input {
      height: tokens.bpk-spacing-lg() * 2;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      outline: none;

      @include utils.bpk-rtl {
        border-right: tokens.$bpk-border-size-sm solid
          tokens.$bpk-canvas-contrast-day !important; // stylelint-disable-line declaration-no-important

        border-left: none;
        border-radius: 0 tokens.$bpk-border-radius-xs
          tokens.$bpk-border-radius-xs 0;
      }
    }
  }

  &__checkoutForm {
    margin-right: 0;

    input {
      height: tokens.bpk-spacing-lg() * 2;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      outline: none;
      text-align: right;

      @include utils.bpk-rtl {
        border-right: none;
        border-left: tokens.$bpk-border-size-sm solid
          tokens.$bpk-canvas-contrast-day !important; // stylelint-disable-line declaration-no-important

        border-radius: tokens.$bpk-border-radius-xs 0 0
          tokens.$bpk-border-radius-xs;
        text-align: left;
      }
    }
  }

  &__nights {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    span {
      display: inline-block;
      padding: 0 tokens.bpk-spacing-base();
      border-radius: tokens.$bpk-border-radius-md;
      background-color: tokens.$bpk-canvas-contrast-day;
      font-size: tokens.$bpk-font-size-xs;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
    }
  }
}
