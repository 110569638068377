@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../SearchControls/mixins';

.GuestsRoomsChildrenSelect {
  display: flex;

  &__portal {
    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: tokens.bpk-spacing-md();
    }
  }

  &__formField {
    @include breakpoints.bpk-breakpoint-tablet-only {
      @include mixins.form-field-spacing;
    }
  }
}
